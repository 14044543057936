const ja = {
    previous: "戻る",
    next: "次",
    submit: "提出",
    complete: "送信完了",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "送信する前に、以下のエラーを修正してください。",
    errorsListNavigationMessage: "クリックして、以下のエラーがあるフィールドに移動します",
    required: "{{field}} - この質問に答えをお願いします",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}-このフィールドには{{length}}文字以上入力できません。",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "回答は正常に送信されました",
    submitFailed: "回答を送信できません",
    expiredInviteNoDraft: "アンケートの有効期限が切れています。",
    expiredInviteDeletedDraft: "アンケートの有効期限が切れています。 入力中の回答はシステムから削除されました。",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "アンケートは次の処理段階に移行し、回答はシステムから削除されました。",
    alreadySubmitted: "アンケートの回答は送信済みです。 回答を読み取り専用モードで表示しています。",
    expiredInviteDraftExists: "アンケートの有効期限が切れています。 保存済みの回答を、読み取り専用モードで表示しています。",
    expiredInvalidToken: "こちらのアンケートには回答できません。 アンケートの有効期限が切れている可能性があります。",
    invalidPublicToken: "URLが無効です。サーベイ・オーガナイザーをチェックしてください",
    missingToken: "URLが無効です。サーベイ・オーガナイザーをチェックしてください",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "URLをコピーしてください",
    draftDialogCopied: "URLをコピーしました。",
    close: "Close",
};

export default ja;

const ru = {
    previous: "Назад",
    next: "Далее",
    submit: "Отправить",
    complete: "Отправка завершена",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Пожалуйста, исправьте следующee:",
    errorsListNavigationMessage: "Нажмите, чтобы перейти к полю со следующей ошибкой",
    required: "{{field}} - Пожалуйста, дайте  ответ на этот вопрос",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}: это поле может содержать не более {{length}} символов.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Ответы успешно отправлены",
    submitFailed: "Отправить ответы не удалось",
    expiredInviteNoDraft: "К сожалению, срок действия этой ссылки на опрос уже истек.",
    expiredInviteDeletedDraft: "Срок действия ссылки на этот опрос истек. Черновики ваших ответов удалены из системы.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Ответы на этот опрос ранее уже были переданы в дальнейшую обработку, и  на данный момент уже удалены из системы без возможности просмотра.",
    alreadySubmitted: "Ответы на этот опрос уже получены. Вы просматриваете свои ответы в режиме только для чтения.",
    expiredInviteDraftExists: "Срок действия этой ссылки на  опрос истек. Вы просматриваете свои сохраненные ответы в режиме только для чтения.",
    expiredInvalidToken: "Этот опрос  недоступен. Возможно, срок действия ссылки на опрос истек.",
    invalidPublicToken: "Недействительный URL-адрес. Пожалуйста, обратитесь к организатору опроса.",
    missingToken: "Недействительный URL-адрес. Пожалуйста, обратитесь к организатору опроса.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Скопируйте URL-адрес",
    draftDialogCopied: "URL-адрес скопирован.",
    close: "Close",
};

export default ru;

const zh = {
    previous: "上一个 ",
    next: "下一个",
    submit: "提交",
    complete: "提交完成",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "在提交前请先修复以下错误：",
    errorsListNavigationMessage: "单击以前往存在以下错误的栏",
    required: "{{field}} - 请回答这一问题",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}} - 此栏不能超过 {{length}} 个字符。",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "已成功提交答案",
    submitFailed: "无法提交答案",
    expiredInviteNoDraft: "此调查邀请已过期。",
    expiredInviteDeletedDraft: "此调查邀请已过期。 您的答案草稿已从系统中清除。",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "此调查已提交并将被进一步处理，答案已从系统中清除。",
    alreadySubmitted: "此调查已提交。 您正在以只读模式查看您的答案。",
    expiredInviteDraftExists: "此调查邀请已过期。 您正在以只读模式查看您保存的答案。",
    expiredInvalidToken: "此调查不可用。 调查邀请可能已过期。",
    invalidPublicToken: "程序上用于指定信息位置的表示方法未出现请与问卷组织者联络",
    missingToken: "程序上用于指定信息位置的表示方法未出现请与问卷组织者联络",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "请复制网址",
    draftDialogCopied: "网址已复制！",
    close: "Close",
};

export default zh;

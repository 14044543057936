import Modal from "react-modal";

const dialogStyle = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: 150,
    },
    content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "900px",
        height: "fit-content",
        maxHeight: "100vh",
        border: "1px solid #e8e8e8",
        borderRadius: "0",
        backgroundColor: "white",
    }
};

export function Dialog({ isOpen, onClose, label, children }) {

    return (
        <Modal appElement={document.getElementById("root")} isOpen={isOpen} onRequestClose={onClose} contentLabel={label} style={dialogStyle}>
            {children}
        </Modal>
    );
}

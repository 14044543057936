const it = {
    previous: "Precedente",
    next: "Successivo",
    submit: "Invia",
    complete: "Invio completato",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Si prega di correggere i seguenti errori prima dell'invio:",
    errorsListNavigationMessage: "Si prega di fare clic per andare al campo con l'errore seguente.",
    required: "{{field}} - Fornisca per favore una risposta a questa domanda",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}: questo campo non può contenere più di {{length}} caratteri.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Risposte inviate con successo",
    submitFailed: "Impossibile inviare le sue risposte",
    expiredInviteNoDraft: "Questo invito al sondaggio è scaduto.",
    expiredInviteDeletedDraft: "Questo invito al sondaggio è scaduto. Le bozze di risposta sono state cancellate dal sistema.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Questo sondaggio è già stato inviato per un'ulteriore elaborazione e le risposte sono state cancellate dal sistema.",
    alreadySubmitted: "Questo sondaggio è già stato inviato. Si sta visualizzando le risposte in modalità di lettura solamente.",
    expiredInviteDraftExists: "Questo invito al sondaggio è scaduto. Si sta visualizzando le risposte salvate in modalità di lettura solamente.",
    expiredInvalidToken: "Questo sondaggio non è disponibile. L'invito al sondaggio potrebbe essere già scaduto.",
    invalidPublicToken: "Il URL non sembra essere valido. Controlli prego con il organiser di indagine.",
    missingToken: "Il URL non sembra essere valido. Controlli prego con il organiser di indagine.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Copiare l'URL",
    draftDialogCopied: "URL copiato!",
    close: "Close",
};

export default it;

import { by639_1 } from "iso-language-codes";
import { useContext } from "react";
import { AppContext } from "src/App";
import logo from "src/assets/logo.svg";
import { useQuery, useRedirect } from "src/utils/hooks";

const languages = {};
for (const key of Object.keys(by639_1)) {
    languages[key] = by639_1[key] || { nativeName: "" };
    const nativeName = languages[key].nativeName.split(",")[0];
    languages[key].nativeName = nativeName.charAt(0).toUpperCase() + nativeName.slice(1);
}

export function Header() {
    const { availableLanguages, selectedLanguage, setSelectedLanguage } = useContext(AppContext);
    const { redirectReplace } = useRedirect();
    const queryParams = useQuery();
    const token = queryParams.get("token");

    function handleChange(event) {
        setSelectedLanguage(event.target.value);
        redirectReplace({ search: `?token=${token}&language=${event.target.value}` });
    }

    return (
        <header className="header">
            <div className="header-container">
                <img className="header-logo" src={logo} alt="Wärtsilä logo" />
                {availableLanguages.length > 0 && (
                    <select
                        className="language-select"
                        aria-label="Select language"
                        onChange={handleChange}
                        value={selectedLanguage}
                    >
                        {availableLanguages.map((lang, key) => (
                            <option key={key} value={lang}>{(languages[lang] || { nativeName: lang }).nativeName}</option>
                        ))}
                    </select>
                )}
            </div>
        </header>
    );
}

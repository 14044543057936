const da = {
    previous: "Tilbage",
    next: "Næste",
    submit: "Send",
    complete: "Indsendelse gennemført",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Ret følgende fejl, før du indsender:",
    errorsListNavigationMessage: "Klik for at navigere til feltet med følgende fejl",
    required: "{{field}} - Svar venligst på spørgsmålet",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}} – Dette felt må ikke have mere end {{length}} tegn.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Svarene er indsendt",
    draftSaveFailed: "Kan ikke indsende svar",
    submitSuccessful: "Answers submitted successfully",
    submitFailed: "Unable to submit answers",
    expiredInviteNoDraft: "Denne undersøgelsesinvitation er udløbet.",
    expiredInviteDeletedDraft: "Denne undersøgelsesinvitation er udløbet. Dine udkast til svar er blevet fjernet fra systemet.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Denne undersøgelse er allerede blevet indsendt til videre behandling, og svar er fjernet fra systemet.",
    alreadySubmitted: "Denne undersøgelse er allerede indsendt. Du ser dine svar i skrivebeskyttet tilstand.",
    expiredInviteDraftExists: "Denne undersøgelsesinvitation er udløbet. Du ser dine gemte svar i skrivebeskyttet tilstand.",
    expiredInvalidToken: "Denne undersøgelse er ikke tilgængelig. Undersøgelsesinvitationen kan være udløbet.",
    invalidPublicToken: "Det lader til, at der er en fejl i web-adressen. Kontakt arrangøren af undersøgelsen.",
    missingToken: "Det lader til, at der er en fejl i web-adressen. Kontakt arrangøren af undersøgelsen.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Kopier URL",
    draftDialogCopied: "URL er kopieret!",
    close: "Close",
};

export default da;

import DOMPurify from "dompurify";
import { BackendApiRequest } from "src/utils/ApiRequest";

function parseStructure(structureResponse) {
    try {
        return structureResponse.data;
    } catch {
        return null;
    }
}

function parseDraft(draftResponse) {
    try {
        return draftResponse.data;
    } catch {
        return null;
    }
}

export async function getFormData(token, language, tempDraft) {
    const query = language ? `?language=${language}` : "";
    const structureResponse = await new BackendApiRequest("api/surveys" + query, token).get();
    const draftResponse = await new BackendApiRequest("api/drafts", token).get();
    const structureLang = (structureResponse && structureResponse.language) ? structureResponse.language : "en";
    const selectedLang = language || structureLang;
    const draftStatus = draftResponse ? draftResponse.status : null;
    const useTempDraft = !draftStatus || draftStatus === "Draft";
    return {
        structure: parseStructure(structureResponse),
        draft: (tempDraft && useTempDraft) ? tempDraft : parseDraft(draftResponse),
        language: selectedLang,
        status: draftStatus,
        expired: structureResponse ? structureResponse.expired : false,
    };
}

export function extractThankYouPage(structure, selfEval) {
    if (!structure) return null;
    let thankYouPanel = (structure.components || []).find(component => component.type === "panel" && component.hidden && component.customClass === "thank-you-page");
    if (selfEval) {
        const seThankYouPanel = (structure.components || []).find(component => component.type === "panel" && component.hidden && component.customClass === "thank-you-page-self-eval");
        if (seThankYouPanel) thankYouPanel = seThankYouPanel;
    }
    if (thankYouPanel) {
        let thankYouPage = "";
        for (const component of (thankYouPanel.components || [])) {
            if (component.type === "content" && component.html) {
                thankYouPage += `<div class="formio-component formio-component-content formio-component-content"><div>${component.html}</div></div>`;
            } else if (component.type === "htmlelement" && component.content) {
                thankYouPage += `<div class="formio-component formio-component-htmlelement formio-component-html"><${component.tag || "p"}>${component.content}</${component.tag || "p"}></div>`;
            }
        }
        return DOMPurify.sanitize(thankYouPage);
    }
    return null;
}

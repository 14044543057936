const ko = {
    previous: "뒤로",
    next: "다음",
    submit: "제출",
    complete: "제출 완료",
    cancel: "취소",
    confirmCancel: "정말 취소 하시겠습니까?",

    error: "제출하기 전에 다음 오류를 수정하십시오. ",
    errorsListNavigationMessage: "다음 오류가 있는 필드로 이동하려면 클릭하세요.",
    required: "{{field}} - 필수 입력 사항",
    unique: "{{field}} 고유해야 합니다.",
    array: "{{field}} 배열이어야 합니다.",
    array_nonempty: "{{field}} 비어 있지 않은 배열이어야 합니다.",
    nonarray: "{{field}} 배열이 아니어야 합니다.",
    select: "{{field}} 잘못된 선택 항목이 포함되어 있습니다.",
    pattern: "{{field}} 은(는) 패턴 {{pattern}} 와(과) 일치하지 않습니다.",
    minLength: "{{field}} 최소 {{length}} 자 이상이어야 합니다.",
    maxLength: "{{field}} - 이 필드는 {{length}} 자를 초과할 수 없습니다.",
    minWords: "{{field}} 은(는) {{length}} 개 단어 이상이어야 합니다.",
    maxWords: "{{field}} 은(는) {{length}} 개 단어를 초과할 수 없습니다.",
    min: "{{field}} 은(는) {{min}} 보다 작을 수 없습니다.",
    max: "{{field}} 은(는) {{max}} 보다 클 수 없습니다.",
    maxDate: "{{field}} 에 {{- maxDate}} 이후 날짜를 포함하면 안 됩니다.",
    minDate: "{{field}} 은(는) {{- minDate}} 이전 날짜를 포함하면 안 됩니다.",
    maxYear: "{{field}} {{maxYear}} 보다 큰 연도를 포함할 수 없습니다.",
    minYear: "{{field}} 은(는) {{minYear}} 보다 작은 연도를 포함할 수 없습니다.",
    invalid_email: "{{field}} 은(는) 유효한 이메일을 입력해야 합니다.",
    invalid_url: "{{field}} 유효한 URL이어야 합니다.",
    invalid_regex: "{{field}} 은(는) 패턴 {{regex}} 와(과) 일치하지 않습니다.",
    invalid_date: "{{field}} 은(는) 유효한 날짜가 아닙니다.",
    invalid_day: "{{field}} 은(는) 유효한 요일이 아닙니다.",
    mask: "{{field}} 마스크와 일치하지 않습니다.",
    valueIsNotAvailable: "{{ field }} 잘못된 값입니다.",
    time: "시간이 잘못되었습니다.",

    draftSaveSuccessful: "초안이 성공적으로 저장되었습니다.",
    draftSaveFailed: "초안을 저장할 수 없습니다",
    submitSuccessful: "답변이 성공적으로 제출되었습니다.",
    submitFailed: "답변을 제출할 수 없습니다",
    expiredInviteNoDraft: "이 설문조사 초대가 만료되었습니다.",
    expiredInviteDeletedDraft: "이 설문조사 초대가 만료되었습니다. 초안 답변이 시스템에서 삭제되었습니다.",
    draftDeleted: "비활동으로 인해 초안 답변이 시스템에서 삭제되었습니다.",
    alreadySubmittedDeleted: "이 설문조사는 이미 추가 처리를 위해 제출되었으며 시스템에서 답변이 삭제되었습니다.",
    alreadySubmitted: "이 설문조사는 이미 제출되었습니다. 답변을 읽기 전용 모드로 보고 있습니다.",
    expiredInviteDraftExists: "이 설문조사 초대가 만료되었습니다. 저장된 답변을 읽기 전용 모드로 보고 계십니다.",
    expiredInvalidToken: "이 설문조사는 이용할 수 없습니다. 설문조사 초대가 만료되었을 수 있습니다.",
    invalidPublicToken: "URL이 유효하지 않은 것 같습니다. 설문조사 주최자에게 확인하시기 바랍니다.",
    missingToken: "URL이 유효하지 않은 것 같습니다. 설문조사 주최자에게 확인하시기 바랍니다.",
    draftDialogContinue: "계속 초안 편집하기",
    draftDialogSaved: "초안이 저장되었습니다!",
    draftDialogInfo: "다음 URL에서 초안 편집을 재개할 수 있습니다.",
    draftDialogCopyButton: "URL 복사",
    draftDialogCopied: "URL이 복사되었습니다!",
    close: "닫기",
};

export default ko;

import { Formio, Providers } from "formiojs";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/App";
import { translations } from "src/translations";
import { BackendApiRequest } from "src/utils/ApiRequest";
import { extractThankYouPage, getFormData } from "src/utils/form";
import { useHasChanged, usePrevious, useQuery, useRedirect, useTranslations } from "src/utils/hooks";
import { StorageProvider } from "src/utils/StorageProvider";
import { DraftDialog } from "./DraftDialog";

let thankYouPage = null;
let forceThankYou = false;
let tempDraft = null;

// Temporary fix for Form.io v4.13.5
let errorsTriggeredOnPage = false;
let triggerErrorScroll = true;

export function Form() {
    const { selectedLanguage, setSelectedLanguage, setAvailableLanguages } = useContext(AppContext);
    const { redirectReplace } = useRedirect();
    const translation = useTranslations();

    const queryParams = useQuery();
    const token = queryParams.get("token");
    const publicToken = queryParams.get("public");

    const [loadingForm, setLoadingForm] = useState(false);
    const [showError, setShowError] = useState(null);
    const [draftModalOpen, setDraftModalOpen] = useState(false);

    const hasTokenChanged = useHasChanged(token);
    const hasPublicTokenChanged = useHasChanged(publicToken);
    const prevLanguage = usePrevious(selectedLanguage);

    function handleSubmit(jwtToken, submission, callback, setAlert) {
        const query = selectedLanguage ? `?language=${selectedLanguage}` : "";
        switch (submission.state) {
            case "draft":
                return new BackendApiRequest(`api/drafts${query}`, jwtToken).post({
                    data: submission,
                }).then(draftResponse => {
                    if (draftResponse) {
                        callback("change", { isValid: true });
                        setAlert("success", translation.get("draftSaveSuccessful"));
                        setDraftModalOpen(true);
                    } else {
                        window.scrollTo(0, 0);
                        callback("change", { isValid: true });
                        callback("submitError", translation.get("draftSaveFailed"));
                        setAlert("danger", translation.get("draftSaveFailed"));
                    }
                });
            case "submitted":
                return new BackendApiRequest(`api/surveys${query}`, jwtToken).post({
                    data: submission,
                }, true).then(submissionResponse => {
                    if (typeof submissionResponse === "object") {
                        if (submissionResponse.message) {
                            window.scrollTo(0, 0);
                            setAlert("danger", getScanFailMessage(submissionResponse.message));
                        } else {
                            tempDraft = null;
                            callback("submitDone", submission);
                            setAlert("success", translation.get("submitSuccessful"));
                            window.scrollTo(0, 0);
                            showThankYouPage();
                            forceThankYou = true;
                        }
                    }
                    else {
                        window.scrollTo(0, 0);
                        callback("submitError", translation.get("submitFailed"));
                        setAlert("danger", translation.get("submitFailed"));
                    }
                });
            default:
                return;
        }
    }

    function closeModal() {
        setDraftModalOpen(false);
    }

    useEffect(() => {
        if (hasTokenChanged || hasPublicTokenChanged || (prevLanguage !== selectedLanguage && prevLanguage !== undefined && prevLanguage !== "")) {
            setShowError(null);
            if (hasTokenChanged || hasPublicTokenChanged) {
                tempDraft = null;
                forceThankYou = false;
                const mainEls = document.getElementsByTagName("main");
                if (mainEls && mainEls[0]) {
                    mainEls[0].style.flexGrow = 1;
                }
            }

            if (token) {
                clearForm();
                setLoadingForm(true);

                async function loadSurvey() {
                    const { structure, draft, language, status, expired } = await getFormData(token, selectedLanguage, tempDraft);
                    const noDraft = status === null;
                    const submitted = !noDraft && !status.startsWith("Draft");
                    const deleted = !noDraft && status.endsWith("Deleted");
                    const selfEval = !!draft && !!draft.data && !!draft.data["META-SelfEvaluation"] && !!draft.data["META-SelfEvaluation"]["Self evaluation"];

                    if (structure) {
                        if (expired && noDraft) {
                            setShowError(translation.get("expiredInviteNoDraft"));
                        } else if (expired && deleted) {
                            setShowError(translation.get("expiredInviteDeletedDraft"));
                        } else {
                            if (submitted && deleted) {
                                setShowError(translation.get("alreadySubmittedDeleted"));
                            } else if (deleted) {
                                setShowError(translation.get("draftDeleted"));
                            } else if (submitted) {
                                setShowError(translation.get("alreadySubmitted"));
                            } else if (expired) {
                                setShowError(translation.get("expiredInviteDraftExists"));
                            }
                            const structureLanguages = ((structure.properties || {}).languages || selectedLanguage).split(",");
                            setAvailableLanguages(structureLanguages.filter(lang => lang));
                            setSelectedLanguage(language);
                            thankYouPage = extractThankYouPage(structure, selfEval);
                            if (forceThankYou && thankYouPage) {
                                setShowError(null);
                                showThankYouPage();
                            } else {
                                buildForm(token, structure, draft, language, submitted || expired || deleted, function (submission, callback, setAlert) {
                                    handleSubmit(token, submission, callback, setAlert);
                                });
                            }
                        }
                    } else {
                        setShowError(translation.get("expiredInvalidToken"));
                    }
                    setLoadingForm(false);
                }

                loadSurvey();
            } else {
                if (publicToken) {
                    clearForm();
                    setLoadingForm(true);

                    async function getNewToken() {
                        const newToken = await new BackendApiRequest(`api/surveys/new?public=${publicToken}`).get();
                        if (newToken) {
                            redirectReplace(`/?token=${newToken}`);
                        } else {
                            setShowError(translation.get("invalidPublicToken"));
                            setLoadingForm(false);
                        }
                    }

                    getNewToken();
                } else {
                    setShowError(translation.get("missingToken"));
                }
            }
        }
        // eslint-disable-next-line
    }, [token, publicToken, selectedLanguage]);

    return (
        <>
            {showError && (
                <div className="alert alert-danger" role="alert">
                    {showError}
                </div>
            )}
            {loadingForm && "Loading..."}
            <div id="formio" />
            <div id="thankyou" />
            <DraftDialog isOpen={draftModalOpen} onClose={closeModal} />
        </>
    );
}

function clearForm() {
    const formioEl = document.getElementById("formio");
    formioEl.textContent = '';
    formioEl.style.display = "none";
    const thankyouEl = document.getElementById("thankyou");
    thankyouEl.innerHTML = '';
}

function showThankYouPage() {
    if (thankYouPage) {
        clearForm();
        const mainEls = document.getElementsByTagName("main");
        if (mainEls && mainEls[0]) {
            mainEls[0].style.flexGrow = 0;
        }
        const element = document.getElementById("thankyou");
        element.innerHTML = thankYouPage;
    }
}

function buildForm(token, structure, draft, language, readOnly, onSubmit) {
    Providers.addProvider("storage", "url", StorageProvider);
    Formio.createForm(document.getElementById("formio"), structure, {
        readOnly,
        language,
        i18n: translations,
        buttonSettings: {
            showCancel: false,
        },
    }).then(function (form) {
        form.language = language;

        if (draft) {
            form.setSubmission(draft).then(() => form.redraw());
        } else {
            form.redraw();
        }

        form.nosubmit = true; // Don't send data to Form.IO server

        form.on("submit", function (submission) {
            form.setAlert(null);
            onSubmit(submission, function (event, ...params) {
                form.emit(event, ...params);
            }, function (type, message) {
                form.setAlert(type, message);
            });
        });

        form.on("render", function () {
            const formioEl = document.getElementById("formio");
            formioEl.style.display = "block";
        });

        form.on("change", function () {
            tempDraft = { ...form.submission };
            triggerErrorScroll = false;
        });

        form.on("nextPage", function () {
            errorsTriggeredOnPage = false;
        });

        form.on("prevPage", function () {
            errorsTriggeredOnPage = false;
        });

        form.on("wizardPageSelected", function () {
            errorsTriggeredOnPage = false;
        });

        form.on("error", function () {
            if (!errorsTriggeredOnPage) {
                errorsTriggeredOnPage = true;
                triggerErrorScroll = true;
            }
            if (triggerErrorScroll) {
                window.scrollTo(0, 0);
            } else {
                triggerErrorScroll = true;
            }
        });
    });
}

function getScanFailMessage(input) {
    let blockedFiles = "";
    let errorFiles = "";
    let result = "";
    for (let i = 0; i < input.length; i++) {
        if (input[i].status === "Blocked") {
            blockedFiles = blockedFiles + "Question: " + input[i].label + "<br>" + "File: " + input[i].files[0].originalName + "<br><br>";
        }
        if (input[i].status === "ERROR") {
            errorFiles = errorFiles + "Question: " + input[i].label + "<br>" + "File: " + input[i].files[0].originalName + "<br><br>";
        }
    }
    if (blockedFiles !== "") {
        result = result + "<b>One or more of Your Attachments has been blocked in malware scanning, please replace the attachment(s) with a clean file</b><br><br>" + blockedFiles;
    }
    if (errorFiles !== "") {
        result = result + "<b>There was a problem with scanning one or more of Your Attachments. Please wait a moment and resubmit the survey or if the problem persists try removing and uploading the file(s) again.</b><br><br>" + errorFiles;
    }
    if (blockedFiles === "" && errorFiles === "") {
        result = "<b>Attachment file malware scan in progress, please wait and retry submission in a moment.</b>"
    }
    return result;
}

import axios from "axios";

export class ApiRequest {

    url;
    endpoint;
    token;

    constructor(url, endpoint, token) {
        this.url = url || "";
        this.endpoint = endpoint || "";
        this.token = token || "";
    }

    async get(id) {
        const fetchURI = this.getRequestURI(id);
        const headers = this.getRequestHeaders();

        try {
            const response = await axios.get(fetchURI, { headers });
            if (response && response.status === 200) {
                return response.data;
            } else {
                this.showError(`GET request failed with status code ${response && response.status}`);
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async post(data, returnError=false) {
        const fetchURI = this.getRequestURI();
        const headers = this.getRequestHeaders({ "Content-Type": "application/json" });

        try {
            const response = await axios.post(fetchURI, data, { headers });
            if (response && response.status === 200) {
                return response.data;
            } else {
                this.showError(`POST request failed with status code ${response && response.status}`);
                return null;
            }
        } catch (error) {
            return this.handleError(error, returnError ? error.response.data : null);
        }
    }

    async delete(id) {
        const fetchURI = this.getRequestURI(id);
        const headers = this.getRequestHeaders();

        try {
            const response = await axios.delete(fetchURI, { headers });
            if (response && response.status === 200) {
                return true;
            } else {
                this.showError(`DELETE request failed with status code ${response && response.status}`);
                return false;
            }
        } catch (error) {
            return this.handleError(error, false) || false;
        }
    }

    handleError(error, returnValue) {
        this.showError(error.message);
        return returnValue || null;
    }

    showError(error) {
        console.error(error);
    }

    getRequestURI(id) {
        let endpoint = `/${this.endpoint}`;
        if (id !== undefined) {
            endpoint = `${endpoint}/${id}`;
        }

        return `${this.url}${endpoint}`;
    }

    getRequestHeaders(additionalHeaders) {
        const headers = { ...additionalHeaders };
        if (this.token) {
            headers["Authorization"] = `Bearer ${this.token}`;
        }
        return headers;
    }
}

export class BackendApiRequest extends ApiRequest {
    constructor(endpoint, token) {
        super(process.env.REACT_APP_API_URL, endpoint, token);
    }
}

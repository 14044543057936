import { useState } from "react";
import { Dialog } from "src/components/Dialog";
import { useTranslations } from "src/utils/hooks";

export function DraftDialog({ isOpen, onClose }) {
    const [copied, setCopied] = useState(false);
    const translation = useTranslations();

    function copy() {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
    }

    function closeDialog() {
        setCopied(false);
        onClose();
    }

    return (
        <Dialog isOpen={isOpen} onClose={closeDialog} label={translation.get("draftDialogContinue")}>
            <div style={{ flex: "1", marginBottom: "25px" }}>
                <p>{translation.get("draftDialogSaved")}</p>
                <p>{translation.get("draftDialogInfo")}</p>
                <div style={{ width: "100%", display: "flex" }}>
                    <input id="#draft-url" type="text" value={window.location.href} readOnly style={{ flex: "1", border: "1px solid #ced4da" }} />
                    <button type="button" className="btn btn-primary btn-md" onClick={copy}>{translation.get("draftDialogCopyButton")}</button>
                </div>
                {copied && <div className="alert alert-success" role="alert" style={{ marginBottom: "0", marginTop: "15px" }}>{translation.get("draftDialogCopied")}</div>}
            </div>
            <button type="button" className="btn btn-secondary btn-md" onClick={closeDialog}>{translation.get("close")}</button>
        </Dialog>
    );
}

import { BackendApiRequest } from "src/utils/ApiRequest";

export class StorageProvider {
    static title = 'StorageProvider';

    async uploadFile(file, fileName, dir, progressCallback, url, options, fileKey, groupPermissions, groupId, abortCallback) {

        const token = (new URLSearchParams(window.location.search)).get('token');
        const presignedURL = await new BackendApiRequest("api/attachments", token).post({
            filename: fileName,
            originalFilename: file.name,
            contentType: file.type
        });

        if (presignedURL.upload_url) {
            const response = await fetch(presignedURL.upload_url, {
                method: 'PUT',
                body: file
            })
            if (!response.ok) throw new Error("File upload failed");

            const attachmentFile = { attachment_identifier: presignedURL.canonical_url, };
            await new BackendApiRequest("api/attachments/scan", token).post(attachmentFile);

            return {
                storage: "url",
                name: fileName,
                url: presignedURL.canonical_url,
                acl: "private",
                size: file.size,
                type: file.type,
            };
        } else {
            throw new Error("Failed to retrieve URL");
        }
    }

    async deleteFile(fileInfo) {
        const token = (new URLSearchParams(window.location.search)).get('token');
        return fetch(fileInfo.url, {
            method: 'DELETE',
            headers: {
                "x-jwt-token": "Bearer " + token
            }
        })
    }

    async downloadFile(file) {
        const token = (new URLSearchParams(window.location.search)).get('token');
        return fetch(file.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                "x-jwt-token": "Bearer " + token
            }
        })
    }
}

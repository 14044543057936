import { Redirect, Route, Switch } from "react-router-dom";
import { Form } from "src/components/Form";

export function Main() {
    return (
        <main className="main">
            <Switch>
                <Route exact path="/">
                    <Form />
                </Route>
                <Route path="/">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </main>
    );
}

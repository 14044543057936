const en = {
    previous: "Back",
    next: "Next",
    submit: "Submit",
    complete: "Submission Complete",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Please fix the following errors before submitting: ",
    errorsListNavigationMessage: "Click to navigate to the field with following error.",
    required: "{{field}} - Required field",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}} - This field can have no more than {{length}} characters.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Answers submitted successfully",
    submitFailed: "Unable to submit answers",
    expiredInviteNoDraft: "This survey invitation has expired.",
    expiredInviteDeletedDraft: "This survey invitation has expired. Your draft answers have been cleaned from the system.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "This survey has already been submitted into further processing and answers cleaned from the system.",
    alreadySubmitted: "This survey has already been submitted. You are viewing your answers in read only mode.",
    expiredInviteDraftExists: "This survey invitation has expired. You are viewing your saved answers in read only mode.",
    expiredInvalidToken: "This survey is unavailable. The survey invitation may have expired.",
    invalidPublicToken: "The URL does not appear to be valid. Please check with the survey organiser.",
    missingToken: "The URL does not appear to be valid. Please check with the survey organiser.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Copy URL",
    draftDialogCopied: "URL copied!",
    close: "Close",
};

export default en;

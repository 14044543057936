import da from "./da";
import de from "./de";
import el from "./el";
import en from "./en";
import es from "./es";
import fi from "./fi";
import fr from "./fr";
import it from "./it";
import ja from "./ja";
import pt from "./pt";
import ru from "./ru";
import tr from "./tr";
import zh from "./zh";
import ko from "./ko";

export const translations = {
    en, zh, da, fi, fr, de, el, it, ja, pt, ru, es, tr, ko,
};

import icon_external_link from "src/assets/icon_external_link.svg";

export function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <a href="https://www.wartsila.com/legal-privacy/privacy" rel="noreferrer noopener" target="_blank">
                  Wärtsilä Privacy Notice <img src={icon_external_link} alt="External link" />
                </a>
            </div>
        </footer>
    );
}

const pt = {
    previous: "Anterior",
    next: "Próximo",
    submit: "Enviar",
    complete: "Conclusão do envio",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Corrija os seguintes erros antes de realizar o envio:",
    errorsListNavigationMessage: "Clique para navegar até o campo com o erro a seguir",
    required: "{{field}} - Dê por favor uma resposta a esta pergunta",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}: este campo não pode ter mais do que {{length}} caracteres.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Respostas enviadas com sucesso",
    submitFailed: "Não foi possível enviar as respostas",
    expiredInviteNoDraft: "O convite para esta pesquisa expirou.",
    expiredInviteDeletedDraft: "O convite para esta pesquisa expirou. Seu rascunho de respostas foi removido do sistema.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Esta pesquisa já foi enviada para processamento adicional e as respostas foram removidas do sistema.",
    alreadySubmitted: "Esta pesquisa já foi respondida e enviada. Você está vendo suas respostas no modo somente leitura.",
    expiredInviteDraftExists: "O convite para esta pesquisa expirou. Você está vendo suas respostas salvas no modo somente leitura.",
    expiredInvalidToken: "O convite para esta pesquisa expirou. Seu rascunho de respostas foi removido do sistema.",
    invalidPublicToken: "O URL não parece ser válido. Verifique por favor com o organiser do exame.",
    missingToken: "O URL não parece ser válido. Verifique por favor com o organiser do exame.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Copie o URL",
    draftDialogCopied: "URL copiado!",
    close: "Close",
};

export default pt;

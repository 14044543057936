const el = {
    previous: "Πίσω",
    next: "Επόμενη Σελίδα",
    submit: "Τέλος Έρευνας - Αποστολή",
    complete: "Η υποβολή ολοκληρώθηκε",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Διορθώστε τα παρακάτω λάθη πριν την υποβολή:",
    errorsListNavigationMessage: "Κάντε κλικ για μετάβαση στο πεδίο με το περιεχόμενο λάθος",
    required: "{{field}} - Προκειμένου να προχωρήσετε στην επόμενη σελίδα παρακαλούμε απαντήστε στην παρακάτω ερώτηση (υποχρεωτική)",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}- Αυτό το πεδίο δεν μπορεί να έχει περισσότερους από {{length}} χαρακτήρες.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Οι απαντήσεις σας υποβλήθηκαν με επιτυχία",
    submitFailed: "Δεν είναι δυνατή η υποβολή απαντήσεων",
    expiredInviteNoDraft: "Η πρόσκλησή σας για αυτή την έρευνα έχει λήξει.",
    expiredInviteDeletedDraft: "Η πρόσκλησή σας για αυτή την έρευνα έχει λήξει. Οι Τα προσχέδια των απαντήσεών σας έχουν διαγραφεί από το σύστημα.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Αυτή η έρευνα έχει υποβληθεί για περαιτέρω επεξεργασία. Οι απαντήσεις σας έχουν διαγραφεί από το σύστημα.",
    alreadySubmitted: "Οι απαντήσεις σας για αυτή την έρευνα έχουν ήδη υποβληθεί. Οι απαντήσεις σας προβάλλονται μόνο για ανάγνωση.",
    expiredInviteDraftExists: "Η πρόσκλησή σας για αυτή την έρευνα έχει λήξει. Οι απθηκευμένες απαντήσεις σας προβάλλονται μόνο για ανάγνωση.",
    expiredInvalidToken: "Αυτή η έρευνα δεν είναι πλέον διαθέσιμη. Η πρόσκλησή σας για αυτή την έρευνα μπορεί να έχει λήξει.",
    invalidPublicToken: "Η ηλεκτρονική διεύθυνση αυτή δεν είναι έγκυρη. Παρακαλούμε επιβεβαιώστε τη με τον υπεύθυνο διεξαγωγής της έρευνας",
    missingToken: "Η ηλεκτρονική διεύθυνση αυτή δεν είναι έγκυρη. Παρακαλούμε επιβεβαιώστε τη με τον υπεύθυνο διεξαγωγής της έρευνας",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Αντιγράψτε τη διεύθυνση URL",
    draftDialogCopied: "Η διεύθυνση URL αντιγράφηκε!",
    close: "Close",
};

export default el;

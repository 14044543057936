const de = {
    previous: "Zurück",
    next: "Weiter",
    submit: "Abschicken",
    complete: "Zusendung erfolgreich",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Bitte korrigieren Sie die folgenden Fehler vor dem Versand:",
    errorsListNavigationMessage: "Bitte klicken Sie, zum Feld mit dem folgendem Fehler zu gehen.",
    required: "{{field}} - Beantworten Sie bitte diese Frage",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}} – Dieses Feld darf nicht mehr als {{length}} Zeichen enthalten.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Antworten erfolgreich versendet",
    submitFailed: "Antworten können nicht versendet werden",
    expiredInviteNoDraft: "Diese Umfrageeinladung ist abgelaufen.",
    expiredInviteDeletedDraft: "Diese Umfrageeinladung ist abgelaufen. Ihre Antwortentwürfe wurden aus dem System entfernt.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Diese Umfrage wurde bereits zur Weiterverarbeitung versendet, die Antworten wurden aus dem System entfernt.",
    alreadySubmitted: "Diese Umfrage wurde bereits eingereicht. Sie sehen Ihre Antworten im schreibgeschützten Modus.",
    expiredInviteDraftExists: "Diese Umfrageeinladung ist abgelaufen. Sie sehen Ihre gespeicherten Antworten im schreibgeschützten Modus.",
    expiredInvalidToken: "Diese Umfrage ist nicht verfügbar. Die Umfrageeinladung ist möglicherweise abgelaufen.",
    invalidPublicToken: "Das URL scheint nicht, gültig zu sein. Überprüfen Sie bitte mit dem Übersichtsorganisator.",
    missingToken: "Das URL scheint nicht, gültig zu sein. Überprüfen Sie bitte mit dem Übersichtsorganisator.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Bitte URL kopieren",
    draftDialogCopied: "URL kopiert!",
    close: "Close",
};

export default de;

import { createContext, useState } from "react";
import { Footer, Header, Main } from "src/components/Layout";
import "./App.css";
import { useQuery } from "src/utils/hooks";

export const AppContext = createContext({
    availableLanguages: [],
    setAvailableLanguages: () => undefined,
    selectedLanguage: undefined,
    setSelectedLanguage: () => undefined,
});

function App() {
    const queryParams = useQuery();
    const defaultLanguage = queryParams.get("language") || "";

    const [availableLanguages, setAvailableLanguages] = useState(defaultLanguage ? [defaultLanguage] : []);
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    return (
        <AppContext.Provider value={{ availableLanguages, setAvailableLanguages, selectedLanguage, setSelectedLanguage }}>
            <div className="app">
                <Header />
                <Main />
                <Footer />
            </div>
        </AppContext.Provider>
    );
}

export default App;

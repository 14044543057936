const tr = {
    previous: "Geri",
    next: "İleri",
    submit: "Gönder",
    complete: "Gönderme İşlemi Tamamlandı",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Göndermeden önce lütfen şu hataları düzeltin:",
    errorsListNavigationMessage: "Hatanın bulunduğu alana gitmek için tıklayın",
    required: "{{field}} - Lütfen bu soruya bir yanıt veriniz",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}- Bu alanda en fazla {{length}} karakter bulunabilir.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Yanıtlar başarıyla gönderildi",
    submitFailed: "Yanıtlar gönderilemiyor",
    expiredInviteNoDraft: "Bu anket davetinin süresi doldu.",
    expiredInviteDeletedDraft: "Bu anket davetinin süresi doldu. Taslak yanıtlarınız sistemden silindi.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Bu anket zaten ileri işlem için gönderildi ve yanıtlar sistemden silindi.",
    alreadySubmitted: "Bu anket zaten gönderildi. Yanıtlarınızı salt okunur modda görüntülüyorsunuz.",
    expiredInviteDraftExists: "Bu anket davetinin süresi doldu. Kaydettiğiniz yanıtları salt okunur modda görüntülüyorsunuz.",
    expiredInvalidToken: "Bu anket kullanılamıyor. Anket davetinin süresi dolmuş olabilir.",
    invalidPublicToken: "URL geçerli değil Lütfen anket düzenleyiciden kontrol edin.",
    missingToken: "URL geçerli değil Lütfen anket düzenleyiciden kontrol edin.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Lütfen URL'yi kopyalayın",
    draftDialogCopied: "URL kopyalandı!",
    close: "Close",
};

export default tr;

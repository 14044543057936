const fr = {
    previous: "Précédent",
    next: "Suivant",
    submit: "Soumettre",
    complete: "Soumission terminée",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Veuillez corriger les erreurs suivantes avant de soumettre:",
    errorsListNavigationMessage: "Cliquez pour accéder au champ présentant l'erreur suivante",
    required: "{{field}} - Veuillez fournir une réponse à cette question s'il vous plaît",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}- Ce champ ne peut pas comporter plus de {{length}} caractères.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Réponses ont été  soumises avec succès",
    submitFailed: "Impossible de soumettre des réponses",
    expiredInviteNoDraft: "L’invitation à cette enquête a expiré.",
    expiredInviteDeletedDraft: "L’invitation à cette enquête a expiré. Vos ébauches de réponses ont été supprimées du système.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Cette enquête a déjà été soumise en vue d'un traitement ultérieur et les réponses ont été supprimées  du système.",
    alreadySubmitted: "Cette enquête a déjà été soumise. Vous visualisez vos réponses en mode lecture seule.",
    expiredInviteDraftExists: "L’invitation à cette enquête a expiré. Vous visualisez vos réponses enregistrées en mode lecture seule.",
    expiredInvalidToken: "Cette enquête n'est pas disponible. L'invitation à cette enquête a peut-être expiré.",
    invalidPublicToken: "L'URL ne semble pas être valide. Veuillez s'il vous plaît vérifier avec l'organisateur de l'enquête.",
    missingToken: "L'URL ne semble pas être valide. Veuillez s'il vous plaît vérifier avec l'organisateur de l'enquête.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Veuillez copier l'URL",
    draftDialogCopied: "URL copiée!",
    close: "Close",
};

export default fr;

const fi = {
    previous: "Takaisin",
    next: "Jatka",
    submit: "Lähetä",
    complete: "Vastaukset on lähetetty",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Korjaa seuraavat virheet ennen vastausten lähettämistä:",
    errorsListNavigationMessage: "Klikkaa siirtyäksesi kenttään, jossa on seuraava virhe",
    required: "{{field}} - Vastaa tähän kysymykseen",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}} – Tässä kentässä voi olla enintään {{length}} merkkiä.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Vastaukset on lähetetty",
    submitFailed: "Vastausten lähetys epäonnistui",
    expiredInviteNoDraft: "Tämä linkki on vanhentunut.",
    expiredInviteDeletedDraft: "Tämä linkki on vanhentunut. Vastausluonnoksesi on poistettu järjestelmästä.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Tämä kysely on jo lähetetty ja vastaukset on poistettu järjestelmästä.",
    alreadySubmitted: "Tähän kyselyyn on jo vastattu. Voit tarkastella vastauksiasi mutta et enää muuttaa niitä.",
    expiredInviteDraftExists: "Tämä linkki on vanhentunut. Voit tarkastella tallentamiasi vastauksiasi mutta et enää muuttaa niitä.",
    expiredInvalidToken: "Tämä kysely ei ole saatavilla. Linkki on ehkä vanhentunut.",
    invalidPublicToken: "URL-osoite ei kelpaa. Ota yhteyttä tiedustelun järjestäjään.",
    missingToken: "URL-osoite ei kelpaa. Ota yhteyttä tiedustelun järjestäjään.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Kopioi URL-osoite",
    draftDialogCopied: "URL-osoite on kopioitu.",
    close: "Close",
};

export default fi;

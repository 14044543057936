const es = {
    previous: "Anterior",
    next: "Siguiente",
    submit: "Enviar",
    complete: "Envío completo",
    cancel: "Cancel",
    confirmCancel: "Are you sure you want to cancel?",

    error: "Por favor corrija los siguientes errores antes de realizar el envio:",
    errorsListNavigationMessage: "Por favor haga clic para navegar al campo con el siguiente error",
    required: "{{field}} - Proporcione por favor una respuesta a esta pregunta",
    unique: "{{field}} must be unique",
    array: "{{field}} must be an array",
    array_nonempty: "{{field}} must be a non-empty array",
    nonarray: "{{field}} must not be an array",
    select: "{{field}} contains an invalid selection",
    pattern: "{{field}} does not match the pattern {{pattern}}",
    minLength: "{{field}} must have at least {{length}} characters.",
    maxLength: "{{field}}: este campo no puede tener más de {{length}} caracteres.",
    minWords: "{{field}} must have at least {{length}} words.",
    maxWords: "{{field}} must have no more than {{length}} words.",
    min: "{{field}} cannot be less than {{min}}.",
    max: "{{field}} cannot be greater than {{max}}.",
    maxDate: "{{field}} should not contain date after {{- maxDate}}",
    minDate: "{{field}} should not contain date before {{- minDate}}",
    maxYear: "{{field}} should not contain year greater than {{maxYear}}",
    minYear: "{{field}} should not contain year less than {{minYear}}",
    invalid_email: "{{field}} must be a valid email.",
    invalid_url: "{{field}} must be a valid url.",
    invalid_regex: "{{field}} does not match the pattern {{regex}}.",
    invalid_date: "{{field}} is not a valid date.",
    invalid_day: "{{field}} is not a valid day.",
    mask: "{{field}} does not match the mask.",
    valueIsNotAvailable: "{{ field }} is an invalid value.",
    time: "Invalid time",

    draftSaveSuccessful: "Draft saved successfully",
    draftSaveFailed: "Unable to save draft",
    submitSuccessful: "Respuestas enviadas con éxito",
    submitFailed: "No se pueden enviar sus respuestas",
    expiredInviteNoDraft: "La invitación a esta encuesta ya caducó.",
    expiredInviteDeletedDraft: "La invitación a esta encuesta ya caducó. El borrador de respuestas ya se eliminó del sistema.",
    draftDeleted: "Your draft answers have been cleaned from the system due to inactivity.",
    alreadySubmittedDeleted: "Esta encuesta ya se envió para ser proceda y las respuestas del sistema han sido ya borradas.",
    alreadySubmitted: "Esta encuesta ya se envió. Está viendo las respuestas en modo de lectura solamente.",
    expiredInviteDraftExists: "La invitación a esta encuesta ya caducó. Está viendo las respuestas guardadas en modo de lectura solamente.",
    expiredInvalidToken: "Esta encuesta no está disponible. Es posible que la invitación a la encuesta ya haya caducado.",
    invalidPublicToken: "El URL no aparece ser válido. Compruebe por favor con el organizador del examen.",
    missingToken: "El URL no aparece ser válido. Compruebe por favor con el organizador del examen.",
    draftDialogContinue: "Continue editing draft",
    draftDialogSaved: "Draft saved!",
    draftDialogInfo: "You can resume editing the draft from this URL:",
    draftDialogCopyButton: "Copie el URL",
    draftDialogCopied: "¡Se copió el URL!",
    close: "Close",
};

export default es;

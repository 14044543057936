import { useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "src/App";
import { translations } from "src/translations";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function useRedirect() {
    const history = useHistory();

    function redirectToPage(path) {
        history.push(path);
    }

    function redirectReplace(path) {
        history.replace(path);
    }

    return { redirectToPage, redirectReplace };
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export function useHasChanged(value) {
    const prevValue = usePrevious(value);
    return prevValue !== value;
}

export function useTranslations() {
    const { selectedLanguage } = useContext(AppContext);
    let language = translations[selectedLanguage];
    if (!language) language = translations.en;
    language.get = function (key) {
        let translation = this[key];
        if (!translation) translation = translations.en[key];
        return translation;
    };
    return language;
}
